type mapType = {
  YYYY: number;
  MMM: string;
  MM: string;
  M: number;
  DDD: string;
  DD: string;
  D: number;
  HH: string;
  H: number;
  m: string;
  s: string;
};

export default class Format {
  DateFormats = {
    formatDateTime: 'DD.MM. YYYY H:m:s',
    formatDateFull: 'DD.MM.YYYY',
    formatDateNoYear: 'D.M',
    formatDateNoYearDay: 'DDD, D.M',
    formatDateNoYearMonth: 'MMM, DD',
    formatTime: 'H:m',
    formatTimeZero: 'HH:m',
    formatTimeSeconds: 'H:m:s',
    formatTimeSecondsZero: 'HH:m:s',
    formatDateDay: 'DDD',
    formatDateDayMonth: 'DD.MM',
    formatFullDayNoDate: 'DDDD D.M',
  };

  parseDate(value: Date | string) {
    if (!(value instanceof Date)) {
      if (value) {
        if (value.indexOf('T') >= 0) {
          const b = value.split(/\D+/);
          if (b.length > 5) {
            value = new Date(Date.UTC(+b[0], +b[1] - 1, +b[2], +b[3], +b[4], +b[5], +b[6]));
          } else {
            value = new Date(Date.UTC(+b[0], +b[1] - 1, +b[2], +b[3], +b[4], 0, 0));
          }
        } else {
          value = value.replace(/-/g, '/');
          value = new Date(value);
        }
      } else {
        value = new Date();
      }
    }
    return value;
  }

  formatDate(value: Date, format = 'DD.MM.YYYY') {
    format = format || this.DateFormats.formatDateTime;
    value = this.parseDate(value);
    const days = ['Ned', 'Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob'],
      fullDays = ['Nedelja', 'Ponedeljek', 'Torek', 'Sreda', 'Četrtek', 'Petek', 'Sobota'],
      months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = value.getFullYear();
    const month = value.getMonth() + 1;
    const date = value.getDate();
    const monthName = months[value.getMonth()];
    const dayName = days[value.getDay()];
    const hour = value.getHours();
    const minute = value.getMinutes();
    const second = value.getSeconds();
    const map = {
      YYYY: year,
      MMM: monthName,
      MM: ('0' + month).slice(-2),
      M: month,
      DDD: dayName,
      DD: ('0' + date).slice(-2),
      D: date,
      HH: ('0' + hour).slice(-2),
      H: hour,
      m: ('0' + minute).slice(-2),
      s: ('0' + second).slice(-2),
      DDDD: fullDays[value.getDay()],
    };

    return format.replace(/Y+|M+|D+|H+|m+|s+/g, (str: string): string => {
      if (map[str as keyof mapType] !== undefined) {
        return map[str as keyof mapType] + '';
      }

      return str;
    });
  }
}
